<template>
  <div>
    <b-row>
      <b-col
        lg="4"
        md="6"
        sm="12"
      >
        <b-card body-class="pb-50">
          <h6 class="mb-3">
            Pending Disburse {{ `(${commissionOverview.total_pending_count})` }}
          </h6>
          <h2 class="font-weight-bolder text-right">
            <vue-numeric
              read-only
              :precision="2"
              currency="MYR"
              separator=","
              :value="commissionOverview.total_pending_amount"
            />
          </h2>
        </b-card>
      </b-col>

      <b-col
        lg="4"
        md="6"
        sm="12"
      >
        <b-card body-class="pb-50">
          <h6 class="mb-3">
            Total Disbursed {{ `(${commissionOverview.total_completed_count})` }}
          </h6>
          <h2 class="font-weight-bolder text-right">
            <vue-numeric
              read-only
              :precision="2"
              currency="MYR"
              separator=","
              :value="commissionOverview.total_completed_amount"
            />
          </h2>
        </b-card>
      </b-col>
    </b-row>

    <!--    @actionBtn-clicked="actionBtnClicked"-->
    <!--    @extraBtn-clicked="extraBtnClicked"-->
    <!--    @payment-provider-btn-clicked="paymentProviderFilterBtnClicked"-->
    <lp-datatable
      ref="CommissionSettlementRef"
      :extra-parameter="extraParam"
      title="Commission Settlement"
      store-module="commissions"
      store-data-endpoint="getCommissionSettlementList"
      store-getter-endpoint="getCommissionSettlementList"
      pagination-state-key="commissionSettlementList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="merchant_id"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @status-filter-btn-clicked="statusFilterBtnClicked"
      @actionBtn-clicked="actionBtnClicked"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import humaniseMixin from '@/common/humanise.mixin'
import Config from '@/views/administration/commissions/formConfig'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

export default {
  components: { LpDatatable },
  mixins: [humaniseMixin],
  data() {
    return {
      extraParam: {
        invoice_status: [
          'PENDING',
        ],
      },
    }
  },
  computed: {
    commissionOverview() {
      const list = this.$store.getters['commissions/getCommissionOverview']
      if (list) {
        return list
      }
      return {
        total_pending_count: 0,
        total_completed_count: 0,
        total_pending_amount: 0,
        total_completed_amount: 0,
        total_count: 0,
      }
    },
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Commission Settlement' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('commissions/getCommissionOverview')
  },
  methods: {
    disburseCommission(obj) {
      // console.log(obj)
      const disburseAmount = obj.total_commission_amount
      const bankAccountNumber = obj.bank_account_number
      const bankName = obj.bank_name

      const settlementChannelHtml = `
            <div class="small d-flex justify-content-between">
              <div>Bank Name</div>
              <h6>
                ${bankName}
              </h6>
            </div>
            <div class="small d-flex justify-content-between">
              <div>Account Number</div>
              <h6>
                ${bankAccountNumber}
              </h6>
            </div>
        `

      const message = `<div class="my-1">
                        <p>Disburse payout with this information?</p>
                        <div class="small d-flex justify-content-between">
                          <div>Amount</div>
                          <h6>
                            MYR ${disburseAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </h6>
                        </div>
                        ${settlementChannelHtml}
                       </div>`

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          const payload = {
            commission_settlement_id: obj.id,
          }

          this.$store.dispatch('commissions/createCommissionPayout', payload).then(() => {
            this.isBusy = false
            this.$refs.CommissionSettlementRef.initDataGrid()
          })
        }
      })
    },
    actionBtnClicked(e) {
      // alert(JSON.stringify(e.rowData.report_id))
      if (e.actionName === 'viewCommissionDetail') {
        this.$router.push({ name: 'administration-commissions-datatable-details', params: { uuid: e.rowData.report_id } })
      }
      if (e.actionName === 'disburseCommission') {
        // alert('disburseCommission')
        this.disburseCommission(e.rowData)
      }
    },
    statusFilterBtnClicked(e) {
      if (e === 'completedSettlementFilter') {
        this.extraParam.invoice_status = ['COMPLETED']
        this.$refs.CommissionSettlementRef.initDataGrid()
      }
      if (e === 'pendingSettlementFilter') {
        this.extraParam.invoice_status = ['PENDING']
        this.$refs.CommissionSettlementRef.initDataGrid()
      }

      console.log('statusFilterBtnClicked', e)
    },
  },
}
</script>
